<template>
  <div style="width: 100%;">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <b-row class="p-2">
      <div class="col-3">
        <div class="pt-4">
          <button type="button" class="btn btn-sm btn-primary" @click="download"> 
            <feather-icon
              size="15"
              icon="DownloadIcon"
              class="mr-50"
            />
            <span style="font-size: 15px">Descargar</span>
          </button>
          <div class="pt-4">
            <div class="custom-file">
              <input
                type="file"
                accept=".xls, .xlsx, .csv"
                ref="file"
                @change="handleFileUpload()"
                class="custom-file-input"
              />
              <label class="custom-file-label" for="validatedCustomFile">
                <small>Actualizar Listado...</small>
              </label>
              <div>
                <ul class="list-group py-2" v-if="file">
                  <li class="list-group-item">
                    <b>NOMBRE:</b>
                    {{file.name}}
                  </li>
                  <li class="list-group-item">
                    <b>TAMAÑO:</b>
                    {{file.size/ 1000 }} KB
                  </li>
                </ul>
                <button class="btn btn-sm btn-primary" @click="submitFile()" v-if="file">Actualizar</button>
              </div>

              <div v-if="errors">
                <div
                  class="alert alert-danger my-1"
                  role="alert"
                  ref="alert"
                  v-for="item in errors.elements"
                  v-bind:key="item"
                >
                  <small>{{item}}</small>
                </div>
                <small
                  v-if="errors.rows.length !== 1"
                >Los registros {{errors.rows}} contienen errores</small>
                <small v-else>El registro {{errors.rows}} contienen errores</small>
              </div>

              <div class="alert alert-primary mt-4" role="alert" ref="alert">
                <small>El archivo a subir debe tener el mismo formato con el cual se exportó.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9" style="height: 400px; overflow-y: auto;  width: 1000px;margin: auto; ">
        <vue-virtual-table  :minWidth="400" :config="tableConfig" :data="items"></vue-virtual-table>
      </div> 
    </b-row>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow
} from "bootstrap-vue";

import PriceService from "@/services/PriceService";
import { setTimeout } from "timers";
import Vue from "vue";
import VueVirtualTable from "vue-virtual-table";

export default {
  name: "cost-all",
  props: {
    type: String
  },
  components: { VueVirtualTable, BRow },
  data() {
    return {
      isLoading: true,
      errors: null,
      file: null,
      items: [],
      tableConfig: [
        {
          prop: "postal_code",
          label: "Codigo Postal",
          sortable: true,
          class: "text-left  pl-4"
        },
        {
          prop: "size",
          label: "Tamaño",
          sortable: true
        },
        {
          prop: "term",
          label: "Plazo",
          sortable: true
        },
        {
          prop: "amount",
          label: "Tarifa",
          sortable: true,
          class: "text-right pr-4"
        }
      ]
    };
  },
  methods: {
    /**
     * @description pending
     */
    loadData() {
      this.isLoading = true;
      PriceService.finalPrice({
        type: this.type
      })
        .then(response => {
          this.isLoading = false;
          const { data } = response.data;
          this.items = data;
        })
        .catch(error => {
          this.isLoading = false;
          console.error(error);
        });
    },

    /**
     * @description pending
     */
    download() {
      this.isLoading = true;
      PriceService.finalPrice(
        {
          type: this.type
        },
        "xls"
      )
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream"
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = "precios.xls";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          this.isLoading = false;
        });
    },

    /**
     * @description pending
     */
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("type", this.type);
      this.isLoading = true;
      PriceService.updateFinalPrice(formData)
        .then(response => {
          const { data } = response.data;
          this.items = data;
          this.file = null;
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Actualizacion`,
              text: `Operacion exitosa`,
              icon: "InfoIcon",
              variant: "info",
            },
          });
        })
        .catch(error => {
          console.error(error);
          this.file = null;
          this.isLoading = false;
          const { response } = error;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              text: `Error al tratar de actualizar`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
          if (response && response.status === 422) {
            const rows = Vue._.uniq(
              Vue._.keys(response.data.errors)
                .sort()
                .join(".")
                .split(".")
                .filter(d => Number.isInteger(Number(d)))
            );
            this.errors = {
              elements: response.data.errors,
              rows
            };
            setTimeout(() => {
              this.errors = null;
            }, 10000);
          }
        });
    },
    /**
     * @description pending
     */
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
.list-group {
  .list-group-item {
    font-size: 12px;
    padding: 0.5rem 1rem;
  }
}
</style>
