<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <div>
        <h3 class="title">DETALLE PRECIO</h3>
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-12">
            <div class="card mx-8 p-1">
              <div class="card-body p-3">
                <RowComponent :title="'ID:'" :content="data.id.toString()" />
                <RowComponent
                  :title="data.percentage > 0 ? 'INCREMENTO:' : 'DESCUENTO:'"
                  :content="data.percentage + '%'"
                />
                <RowComponent
                  :title="'CREADO POR :'"
                  :content="
                    data.created_by.name + ' ' + data.created_by.last_name
                  "
                />
                <div>
                  <p>
                    <b>CREADO :</b>
                    <span>{{ data.created_at | date_format }}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <b>ACTUALIZADO :</b>
                    <span>{{ data.updated_at | date_format }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            class="mr-1 float-right"
            variant="primary"
            @click="onClose"
            >Cerrar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
 BButton
} from "bootstrap-vue";

export default {
  name: "PriceView",
  components: {
    BButton
  },
  props: {
    data: {
      price: Object,
      created_by: Object,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>


<style lang="scss" scoped>
</style>