import axios from 'axios';

export default class PriceService {

  /**
   * @description login
   * @param { username | login, password } values
   */
  static index() {
    return axios.post(process.env.VUE_APP_API + '/prices');
  }

	/**
	 * @description store data
	 * @param data { location_id, street, height,  postal_code } values
	 */
  static save(data, price_id) {
    return price_id ? this.updated(price_id, data) : this.store(data);
  }

  /**
   * @description store data
   * @param data { location_id, street, height,  postal_code } values
   */
  static store(data) {
    return axios.post(process.env.VUE_APP_API + '/prices', data);
  }

  /**
   * @description delete a element
   * @param { id } id point
   */
  static destroy({ id }) {
    return axios.delete(process.env.VUE_APP_API + '/prices/' + id);
  }

  /**
   * @description update a element
   * @param { id } id point
   * @param values { location_id, street, height,  postal_code } update values
   */
  static updated(id, values) {
    return axios.put(process.env.VUE_APP_API + '/prices/' + id, values);
  }

  /**
   * @description get a element by id
   * @param { id } id point
   */
  static show(id) {
    return axios.get(process.env.VUE_APP_API + '/prices/' + id);
  }

  /**
   * @description get a element by id
   * @param { id } id point
   */
  static finalPrice(params, type = 'json') {
    const responseType = (type === 'json' ? null : 'blob');
    const _params = Object.assign({
      format: type
    }, params);
    return axios.get(process.env.VUE_APP_API + '/final-price', {
      params: _params,
      responseType: responseType
    });
  }


  /**
   * @description get a element by id
   */
  static updateFinalPrice(formData) {
    return axios.post(process.env.VUE_APP_API + '/final-price', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

}
