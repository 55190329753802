<template>
  <div ref="container">
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <b-card>
      <b-tabs>
        <b-tab title="DESCUENTOS" active>
          <b-card-body class="card-body py-2 px-0">
            <b-row class="row pb-2 px-2">
              <b-col cols="4">
                <button class="btn btn-sm button-primary" @click="onAdd">
                  <feather-icon
                    @click="onEdit(item)"
                    icon="PlusSquareIcon"
                    class="mr-1"
                  />
                  <span style="font-size: 15px">Agregar Precio</span>
                </button>
              </b-col>
              <b-col cols="8">
                <filter-bar-component
                  v-on:search="onSearch"
                ></filter-bar-component>
              </b-col>
            </b-row>
            <full-table-component
              ref="fulltable"
              :fields="fields"
              :perPage="100"
              url="/prices"
              :select="false"
            >
              <template slot="buttons-slot" slot-scope="props">
                <b-dropdown
                  class="p-0"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      size="20"
                      icon="ListIcon"
                    />
                  </template>
                  <b-dropdown-item @click="onView(props.rowData)">
                    <feather-icon
                      size="20"
                      icon="SearchIcon"
                    />
                    Ver detalles
                  </b-dropdown-item>
                  <b-dropdown-item @click="onEdit(props.rowData)">
                    <feather-icon
                      size="20"
                      icon="Edit3Icon"
                    />
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="onDelete(props.rowData)">
                    <feather-icon
                      size="20"
                      icon="TrashIcon"
                    />
                    Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </full-table-component>
          </b-card-body>
        </b-tab>
        <b-tab title="ACTUALIZAR PRECIOS" @click="loadClient = true">
          <b-card-body class="card-body py-2 px-0" v-if="loadClient">
            <cost-all type="client" />
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BTabs,
  BTab,
  BCardBody,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import FullTableComponent from "@/layouts/components/FullTableComponent";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";

import PriceService from "@/services/PriceService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import PriceEdit from "./PriceEdit";
import PriceView from "./PriceView";
import CostAll from "./CostAll";

export default {
  name: "PriceAll",
  components: {
    BCard,
    BTabs,
    BTab,
    BCardBody,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    FilterBarComponent,
    FullTableComponent,
    CostAll,
  },
  data() {
    return {
      // loadDeposit: false,
      loadClient: false,
      isLoading: false,
      fullPage: true,
      fields: [
        {
          name: "id",
          title: "ID",
          sortField: "id",
        },
        {
          name: "created_at",
          title: "CREADO",
          formatter: (value) => {
            return UtilService.date(value);
          },
        },
        {
          name: "percentage",
          title: "% DESCUENTO / INCREMENTO",
          sortField: "percentage",
          formatter: (value) => {
            let text = "";
            if (value > 0) {
              text = "INCREMENTO";
            } else if (value < 0) {
              text = "DESCUENTO";
            }
            return ` ${text} ${value} %`;
          },
        },
        {
          name: "is_prepago",
          title: "Pre-pago",
          sortField: "is_prepago",
          formatter: (value) => {
            return value ? "pre-pago" : "post-pago";
          },
        },
        {
          name: "created_by",
          title: "CREADO POR",
          sortField: "created_by",
          formatter: (value) => {
            return `${value.name} ${value.last_name}`;
          },
        },

        {
          name: "buttons-slot",
          title: "ACCIONES",
          titleClass: "text-right",
          dataClass: "text-right align-middle",
        },
      ],
    };
  },
  methods: {
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el precio con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        PriceService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async onAdd() {
      this.$modal.show(
        PriceEdit,
        {
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
    async onView(item) {
      this.$modal.show(
        PriceView,
        {
          data: item,
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
    async onEdit(item) {
      this.$modal.show(
        PriceEdit,
        {
          price_id: item.id,
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
  },
  computed: {},
  mounted() {
    if (!UserService.isAdminOrStaff()) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
