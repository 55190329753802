
<template>
  <div class="m-3">
    <div class="card-body card-modal">
      <div>
        <h3 class="title">Agregar Precio</h3>
        <div class="row justify-content-center">
          <div class="col-12">
            <validation-observer ref="addPriceForm">
              <b-form @submit="onSubmit">
                <b-form-group label="Descuento / Incremento">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|min:-100|max:100"
                  >
                    <b-form-input
                      size="sm"
                      v-model="price.percentage"
                      type="range"
                      name="percentage"
                      :state="errors.length > 0 ? false : null"
                      min="-100"
                      max="100"
                      step="1"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div class="my-2">
                    <div v-if="price.percentage > 0">
                      <span class="mr-1" style="color: green">
                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                      </span>
                      <b>INCREMENTO</b>
                      del {{ price.percentage }} % sobre el costo del envio.
                    </div>
                    <div v-if="price.percentage < 0">
                      <span class="mr-1" style="color: red">
                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                      </span>
                      <b>DESCUENTO</b>
                      del {{ price.percentage * -1 }} % sobre el costo del
                      envio.
                    </div>
                    <div v-if="price.percentage == 0">
                      {{ price.percentage }} %
                    </div>
                  </div>
                </b-form-group>

                <b-form-group>
                  <b-form-radio-group
                    class="price-options"
                    id="options"
                    name="options"
                    v-model="price.option"
                    :options="options"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
            >Guardar</b-button
          >
          <b-button
            class="mr-1 float-right"
            variant="outline-primary"
            @click="close"
            >Cancelar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { required } from '@validations'

import PriceService from "./../../../services/PriceService";

export default {
  name: "PriceEdit",
  components: {
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    onClose: Object,
    onSave: Object,
    price_id: {
      type: Number,
    },
  },
  data() {
    return {
      required,
      price: {
        percentage: 0,
        option: null,
      },
      options: [
        {
          text: "Aplicar a todos los Clientes Pre-Pago (Existentes + Nuevos).",
          value: "OP1",
        },
        {
          text: "Aplicar a todos los Clientes Pre-Pago Nuevos.",
          value: "OP2",
        },
        {
          text: "Aplicar a todos los Clientes Pre-Pago Existentes. ",
          value: "OP3",
        },
        { text: "No aplicar a nadie", value: "OP4" },
      ],
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.addPriceForm.validate().then((valid) => {
        if (valid) {
          PriceService.save(this.price, this.price.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
            })
            .catch((error) => {
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Actualizar`,
                  text: `Error al tratar de almacenar este elemento`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    /**
     *
     */
    close() {
      this.$emit("close");
      this.onClose.handler();
    },
  },
  mounted() {
    if (this.price_id) {
      PriceService.show(this.price_id).then(({ data }) => {
        this.price = data.data;
      });
    }
  },
};
</script>

<style lang="scss">
.price-options {
  .custom-control {
    margin-top: 10px !important;
  }
}
</style>